import { VueWizard } from "@/vue-wizard";
import { Component } from "vue-property-decorator";
import $ from "jquery";
import { store, storeTypes } from "@/app/store";
import { userTypes } from "@/app/store/modules/user";
import WebSocketService from "@/app/services/web-socket";

//require('/src/assets/css/style.css');
require('/src/assets/css/jquery.maskedinput.min.js');
require('/src/assets/css/bootstrap4.min.css');
require('/src/assets/css/dataTables.bootstrap4.min.css');
require('/src/assets/css/jquery.dataTables.min.js');

//require('/src/assets/css/dataTables.bootstrap4.min.js');

@Component({ name: "nav-bar" })
export default class NavBar extends VueWizard {

    public webSocketService: WebSocketService = WebSocketService.instance();
    public showModal = false;

    get disconnectedStatus(): number {
        const disconnectedStatus = this.webSocketService.disconnectedStatus;
        this.evaluateShowedModal();
        return disconnectedStatus;
    }

    get isSessionReconnected(): boolean {
        const isSessionReconnected = this.webSocketService.isSessionReconnected;
        this.evaluateShowedModal();
        return isSessionReconnected;
    }

    get isSessionDuplicated(): boolean {
        const isSessionDuplicated = this.webSocketService.isSessionDuplicated;
        this.evaluateShowedModal();
        return isSessionDuplicated;
    }

    get isSessionTotallyDisconnected(): boolean {
        const isSessionTotallyDisconnected = this.webSocketService.isSessionTotallyDisconnected;
        this.evaluateShowedModal();
        return isSessionTotallyDisconnected;
    }

    evaluateShowedModal(): void {
        if (this.webSocketService.isSessionTotallyDisconnected || this.webSocketService.isSessionDuplicated || this.webSocketService.isSessionReconnected || this.webSocketService.disconnectedStatus > 0) {
            this.showModal = true;
            setTimeout(() => $('#staticBackdrop').modal('show'), 1000);
        } else {
            $('#staticBackdrop').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            this.showModal = false;
        }
    }

    async logout(): Promise<any> {
        const modal: any = $('#closeSessionModal');
        modal.modal('hide');
        await store.dispatch(storeTypes.auth.actions.logout());
     
    }

    get FullName(): string {
        const firstName =
            this.user && this.user.name && this.user.name.firstName
                ? this.user.name.firstName
                : "";
        const lastName =
            this.user && this.user.name && this.user.name.lastName
                ? this.user.name.lastName
                : "";
        return `${firstName} ${lastName}`;
    }

    get initials(): string {
        const firstName =
            this.user && this.user.name && this.user.name.firstName
                ? this.user.name.firstName.substr(0, 1).toUpperCase()
                : "";
        const lastName =
            this.user && this.user.name && this.user.name.lastName
                ? this.user.name.lastName.substr(0, 1).toUpperCase()
                : "";
        return `${firstName}${lastName}`;
    }

    get isLoggedIn(): boolean {
        return store.getters[userTypes.getters.isLoggedIn];
    }

    get loadingLogin(): boolean {
        return store.state.user!.loading;
    }

    get user(): any {
        return store.state.user!.user;
    }

}